<template>
  <v-container>
    <v-card class="pa-4" shaped>
      <v-card-title>Search User by Email</v-card-title>
      <v-card-text>
        <v-row class="mb-3">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="searchEmail"
              outlined
              rounded
              dense
              append-icon="mdi-magnify"
              @click:append="searchUserByEmail"
              clearable
              label="Enter Email to Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="userFound">
          <h3>User Details:</h3>
          <p><strong>Email:</strong> {{ userDetails.email }}</p>
          <p><strong>User ID:</strong> {{ userDetails.uid }}</p>
        </div>
        <div v-else-if="searchAttempted">
          <p>{{ message }}</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      searchEmail: '',
      userFound: false,
      userDetails: {},
      message: '',
      searchAttempted: false,
    };
  },
  methods: {
    ...mapActions('api', ['httpGet']),

async searchUserByEmail() {
  if (this.searchEmail.trim()) {
    this.searchAttempted = true;
    try {
      const response = await this.httpGet({
        withAuth: true,
        url: `/admin/search_user?email=${encodeURIComponent(this.searchEmail)}`,
      });
      // Adjusted to check response.data for the user object
      if (response && response.data && response.data.email) {
        this.userDetails = response.data; // Assuming the user object is wrapped in a `data` property
        this.userFound = true;
        this.message = '';
      } else {
        this.userFound = false;
        this.message = 'User does not exist';
      }
    } catch (error) {
      console.error('Error searching for user:', error);
      this.userFound = false;
      this.message = 'Failed to search for user';
    }
  } else {
    this.searchAttempted = false;
    this.userFound = false;
    this.message = 'Please enter an email to search';
  }
},

  },
};
</script>

<style scoped>
.txt {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>
